import React from "react";
import { Link } from 'react-router-dom';
import "../styles/footer.css";
import { Link as ScrollLink } from 'react-scroll';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        behavior: "smooth",
        top: element.offsetTop
      });
    }
  };

  return (
    <footer>
    
        <div className="footer">
          <div className="footer-container">
            <div className="footer-info">
              <h3>CONTATO</h3>
              <ul>
                <li>
                  <img src="images/whatsapp-icon.png" width="30px" height="auto" alt="WhatsApp" />
                  <a href="https://api.whatsapp.com/send?phone=5551999785744&text=Ol%C3%A1%2C%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es"> WHATSAPP (51)99978-5744 </a>
                </li>
                <li>
                  <img src="images/location-icon.png" width="30px" height="auto" alt="WhatsApp" />
                  {location.pathname === '/' ?
                    <ScrollLink to="localBar" smooth={true} duration={500} className="scrollLink"> LOCALIZAÇÃO </ScrollLink>
                    :
                    <a href="/" onClick={() => scrollToSection('localBar')} className="scrollLink"> LOCALIZAÇÃO </a>
                  }
                </li>
              </ul>
            </div>
            <div className="footer-info">
              <h3>INFORMAÇÕES</h3>
              <ul>
                <li>
                  <img src="images/eye-icon.png" width="30px" height="auto" alt="WhatsApp" />
                  {location.pathname === '/' ?
                    <ScrollLink to="examesBar" smooth={true} duration={500} className="scrollLink"> EXAMES </ScrollLink>
                    :
                    <a href="/" onClick={() => scrollToSection('examesBar')} className="scrollLink"> EXAMES </a>
                  }
                </li>
                <li>
                  <img src="images/blog-icon.png" width="30px" height="auto" alt="Blog" />
                  <Link to="/blog" target="_top">  BLOG </Link>
                </li>
              </ul>
            </div>
            <div className="footer-info">
              <h3>REDES SOCIAIS</h3>
              <ul>
                <li>
                  <img src="images/facebook-icon.png" width="30px" height="auto" alt="Facebook" />
                  <a href="https://www.facebook.com/optocomvisao" target="_blank" rel="noreferrer"> FACEBOOK </a>
                </li>
                <li>
                  <img src="images/instagram-icon.png" width="30px" height="auto" alt="Instagram" />
                  <a href="https://www.instagram.com/optocomvisao" target="_blank" rel="noreferrer"> INSTAGRAM </a>
                </li>
              </ul>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=5551999785744&text=Ol%C3%A1%2C%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es">
            <img className="whatsapp-button" src="images/whatsapp-api.webp" width="48px" height="48px" alt="whatsapp" />
          </a>
          <div className="footer-company">
            <p>© 2005 - 2025 &nbsp; OPTOCOM VISÃO <br /> CLÍNICA DE OPTOMETRIA COMPORTAMENTAL SS LTDA <br /> AV. FLORES DA CUNHA, 1323   SALA 03   CACHOEIRINHA - RS </p>
          </div>
          <div className="footer-bottom">
            <a href="https://mfdigital.top/"> Desenvolvido por MF Digital</a>
          </div>
        </div>
      
    </footer>
  );
}

export default Footer;
