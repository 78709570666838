/* eslint-disable react/style-prop-object */
import React from 'react';
import '../styles/home.css';
import video from '../assets/exame-optometrico.mp4';



function Home() {
  return (
    <div className="home">
      <div className="topContainer"> 
       
    <video src={video} preload autoPlay playsInline muted loop width="100%" height="100%" >  </video>
      <div className="overlay">  </div>  
</div>  
  <section id='examesBar' > 
    <h1>EXAMES DE VISÃO</h1> </section>
      <div className="mainContainer">
       <div className="firstContainer">     
        <div className="cardContainer">
        
      <div className="cardContent"> 
       <h2> RECEITAS PARA ÓCULOS / LENTES DE CONTATO</h2> 
        <div className="card">              
          <img src="images/receita-para-oculos.webp" width="100%" height="auto"  alt="Receita para Óculos"/> </div> </div>

      <div className="cardContent">
        <h2>AVALIAÇÃO VISUAL OPTOMÉTRICA COMPLETA</h2>
          <div className="card">
            <img src="images/avaliacao-visual.webp" width="100%" height="auto" alt="Avaliação Visual"/> </div> </div> 
     
      <div className="cardContent">
       <h2>ACUIDADE VISUAL PARA CNH E EMPRESAS</h2>
         <div className="card">
           <img src="images/acuidade-visual.webp" width="100%" height="auto" alt="ACUIDADE VISUAL PARA CNH E EMPRESAS"/> </div> </div> 

      <div className="cardContent">  
       <h2>ESTRESSE VISUAL / CUIDADOS OCULARES</h2>
        <div className="card">       
          <img src="images/estresse-visual.webp" width="100%" height="auto" alt="ESTRESSE VISUAL CUIDADOS OCULARES"/> </div> </div> 

      
      <div className="cardContent">
        <h2>ADAPTAÇÃO DE LENTES DE CONTATO</h2>
          <div className="card">
           <img src="images/lentes-de-contato.webp" width="100%" height="auto" alt="ADAPTAÇÃO DE LENTES DE CONTATO"/> </div> </div>

      <div className="cardContent">
        <h2>TERAPIA VISUAL - AMBLIOPIA E ESTRABISMO</h2>
          <div className="card">
            <img src="images/terapia-visual.webp" width="100%" height="auto" alt="TERAPIA VISUAL AMBLIOPIA E ESTRABISMO"/> </div> </div> 
       </div>
     </div>

     <section id='localBar' > 
        <h1>LOCALIZAÇÃO</h1> </section> 
        <iframe 
  title="maps" 
  width="750px"
  height="450px"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src="https://www.google.com/maps/embed?origin=mfe&pb=!1m4!2m1!1sAv.+Gen.+Flores+da+Cunha,+1323+-+Sala+03+-+Vila+Imbui,+Cachoeirinha+-+RS,+94910-002!5e0!6i15">
</iframe>


<section id='agendeBar' > 
            <h1>FALE CONOSCO / AGENDE SUA CONSULTA</h1>
    </section> 
      <div className="secondContainer">
      <a href="https://api.whatsapp.com/send?phone=5551999785744&text=Ol%C3%A1%2C%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es">  
       <p> VAMOS MARCAR SUA CONSULTA? <br /> CLICK AQUI! </p> </a>
       <a href="https://api.whatsapp.com/send?phone=5551999785744&text=Ol%C3%A1%2C%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es"> <img src="images/whatsapp.png" width="300px" height="auto" alt="TERAPIA VISUAL AMBLIOPIA E ESTRABISMO"/> </a>
      </div>
     

    <section id='membroBar' > 
     <h1>MEMBRO AFILIADO</h1> </section>
      <div className="thirdContainer"> 
      <div>
       <a href="https://croors.org.br/">
        <img src="images/croo-rs.webp" width="360px" height="auto" alt="CROO-RS"/> </a>
         </div>
      <div>
       <a href="https://www.cboo.org.br/">        
        <img src="images/cboo.webp" width="360px" height="auto" alt="CBOO"/> </a> 
        </div>
      </div>

    <section id='testBar' > 
     <h1>TESTE SUA VISÃO</h1> </section>
      <div className="fourthContainer"> 
      <div>  
       <p>TESTE SUA VISÃO <br/> APP OMS</p>   
      </div> 
      <div>
       <a href="https://qrco.de/beBZ3B">
        <img src="images/teste-de-visao-oms.png" width="300px" height="auto" alt="TESTE DE VISÃO OMS"/> </a>
      </div>
      

      </div>
     </div>
    </div>  

    )
  }
  
  export default Home;
  